import { createPagesBrowserClient } from "@supabase/auth-helpers-nextjs";
import { CheckIcon } from "lucide-react";
import { useConfigContext } from "~/components/common/config/config-context";
import { Button } from "~/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "~/components/ui/card";

export const ConnectHotel = () => {
  const { userProfile } = useConfigContext();
  console.log(`muly:connect-hotel:ConnectHotel`, {
    userProfile,
  });

  const isLogin = userProfile?.email;

  const logout = async () => {
    const supabase = createPagesBrowserClient();
    const { error } = await supabase.auth.signOut();
    if (error) {
      console.error("Error logging out:", error.message);
    }
  };

  return (
    <div className="grid min-h-screen place-items-center">
      <Card className="mx-auto w-full max-w-3xl">
        <CardHeader>
          <CardTitle>Welcome to Rate Quantum!</CardTitle>
          <CardDescription>
            We're thrilled to have you on board. To unlock the full potential of
            our platform, let's connect your hotel(s) and start optimizing your
            revenues.
          </CardDescription>
        </CardHeader>
        <CardContent>
          <div className="grid gap-6">
            {isLogin && (
              <div>
                <h3 className="text-hsm">Login:</h3>
                <p className="mt-2 text-blueblack2">
                  You are already logged in as <b>{userProfile?.email}</b>. If
                  this is the wrong login, please logout and try to disconnect
                  and reconnect the app again so we can link the correct
                  account.
                </p>
                <Button onClick={logout} className="mt-2">
                  Logout
                </Button>
              </div>
            )}
            <div>
              <h3 className="text-hsm">Key Points:</h3>
              <ul className="mt-2 space-y-2 text-blueblack2">
                <li className="flex items-start gap-2">
                  <CheckIcon className="mt-1 size-5 text-blueblack" />
                  <span>
                    Connect your hotels through the MEWS or Cloudbeds
                    marketplaces to access our powerful features
                  </span>
                </li>
                <li className="flex items-start gap-2">
                  <CheckIcon className="mt-1 size-5 text-blueblack" />
                  <span>Simple integration process for quick setup</span>
                </li>
                <li className="flex items-start gap-2">
                  <CheckIcon className="mt-1 size-5 text-blueblack" />
                  <span>Gain valuable insights and tools once connected</span>
                </li>
              </ul>
            </div>
            <div>
              <h3 className="text-hsm">Steps to Connect:</h3>
              <ol className="mt-2 space-y-2 text-blueblack2">
                <li className="flex items-start gap-2">
                  <div className="flex size-5 items-center justify-center rounded-full bg-blueblack text-grey4">
                    1
                  </div>
                  <span>Log in to your MEWS or Cloudbeds account</span>
                </li>
                <li className="flex items-start gap-2">
                  <div className="flex size-5 items-center justify-center rounded-full bg-blueblack text-grey4">
                    2
                  </div>
                  <span>Navigate to your system's marketplace</span>
                </li>
                <li className="flex items-start gap-2">
                  <div className="flex size-5 items-center justify-center rounded-full bg-blueblack text-grey4">
                    3
                  </div>
                  <span>Search for the 'Rate Quantum' app</span>
                </li>
                <li className="flex items-start gap-2">
                  <div className="flex size-5 items-center justify-center rounded-full bg-blueblack text-grey4">
                    4
                  </div>
                  <span>Follow the prompts to install and connect the app</span>
                </li>
              </ol>
            </div>
            <div>
              <h3 className="text-hsm">Need Assistance?</h3>
              <p className="mt-2 text-blueblack2">
                If you encounter any issues or have questions, our support team
                is here to help! Reach out to us at{" "}
                <a
                  href="mailto:support@ratequantum.com"
                  className="font-medium text-blueblack hover:underline"
                >
                  support@ratequantum.com
                </a>{" "}
                for personalized guidance.
              </p>
            </div>
          </div>
        </CardContent>
        {/*<CardFooter className="flex justify-end">*/}
        {/*  <Button>Let's Get Started</Button>*/}
        {/*</CardFooter>*/}
      </Card>
    </div>
  );
};
